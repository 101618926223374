import { AxiosRequestConfig } from "axios";
import {
  create,
  ApiResponse,
  CLIENT_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR
} from "apisauce";

import { BASE_URL, ERROR_CODES } from "@/res/strings";

import CycleStore from "@/services/cycle/cycle.store";
import { CycleDTO } from "@/services/cycle/cycle.dto";

const APIKit = create({
  baseURL: BASE_URL,
  timeout: 1200000
});

APIKit.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const cycle: CycleDTO = CycleStore.state;
  if (cycle.cycleId) {
    config.headers.common.cycle = cycle.cycleId;
  }

  console.log(
    `Going to call ${config.method?.toUpperCase()} ${
      config.url
    } with headers ${JSON.stringify(config.headers)}` +
      (config.data ? `with ${config.data}` : "")
  );
  return config;
});

APIKit.addMonitor((response: ApiResponse<any, any>): void => {
  if (response.config) {
    console.log(
      `Response of ${response.config.method?.toUpperCase()} ${
        response.config.url
      } received with status code ${
        response.status
      } and it took ${(response.duration || 0) / 1000} s ` +
        (response.data ? `and data ${response.data}` : "")
    );
  }
});

const handleError = (problem: string): string => {
  let errorString: string;

  switch (problem) {
    case NETWORK_ERROR:
      errorString = ERROR_CODES.NO_INTERNET;
      break;

    case CLIENT_ERROR:
      errorString = ERROR_CODES.PROBLEM_IN_DATA;
      break;

    case SERVER_ERROR:
      errorString = ERROR_CODES.SERVER_ERROR;
      break;

    case TIMEOUT_ERROR:
      errorString = ERROR_CODES.SERVER_TIMEOUT;
      break;

    case CONNECTION_ERROR:
      errorString = ERROR_CODES.UNABLE_TO_CONNECT_TO_SERVER;
      break;

    default:
      errorString = ERROR_CODES.UNKNOWN_ERROR;
      break;
  }

  return errorString;
};

const Get = async (url: string, params?: Record<string, any>) => {
  const response = await APIKit.get(`${BASE_URL}${url}`, params);

  if (!response.ok) {
    const errorString: string = handleError(response.problem);
    throw Error(errorString);
  }

  return response.data;
};

const Post = async (url: string, data?: Record<string, any>) => {
  const response = await APIKit.post(`${BASE_URL}${url}`, data);

  if (!response.ok) {
    const errorString: string = handleError(response.problem);
    throw Error(errorString);
  }

  return response.data;
};

const Patch = async (url: string, data?: Record<string, any>) => {
  const response = await APIKit.patch(`${BASE_URL}${url}`, data);

  if (!response.ok) {
    const errorString: string = handleError(response.problem);
    throw Error(errorString);
  }

  return response.data;
};

const Delete = async (url: string, params?: Record<string, any>) => {
  const response = await APIKit.get(`${BASE_URL}${url}`, params);

  if (!response.ok) {
    const errorString: string = handleError(response.problem);
    throw Error(errorString);
  }

  return response.data;
};

export { Get, Post, Patch, Delete };
