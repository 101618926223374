export const BASE_URL = process.env.VUE_APP_SERVER_URL;

export const ERROR_CODES = {
  NO_INTERNET: "No internet connection",

  PROBLEM_IN_DATA: "Incorrect data sent",

  SERVER_ERROR: "Server error",

  SERVER_TIMEOUT: "Too much time taken",

  UNABLE_TO_CONNECT_TO_SERVER: "Cannot connect to server",

  UNKNOWN_ERROR: "Something went wrong",

  NO_DATA_IN_STORE: "No data in store"
};
